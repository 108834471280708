/*
 * Frederik Bring 2017
 * Rheinschafe GmbH
 * www.rheinschafe.de
*/

$duisburgDE-primary: 		#0038a5;
$duisburgDE-screen-sm: 		748px;
$duisburgLive-screen-sm:	768px;

body {
  background-color: transparent;
}

#dl-widget {
  padding: 0 25px 15px;
}

.dl-logo,
.dl-footer {
  padding: 30px;
  padding-bottom: 0;
  text-align: center
}

.dl-logo {
  img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}

@media (min-width: $duisburgDE-screen-sm) and (max-width: $duisburgLive-screen-sm - 1px) {
  .col-sm-6 {
	  width: 50%;
  }
}

body {
  &.duisburg-de {
    // Meta information
    .date-time {
      color: $duisburgDE-primary;
    }

    // Tags
    .tags li{
      a {
        &:hover {
          background-color: $duisburgDE-primary;
          border-color: $duisburgDE-primary;
        }
      }
      span.tipp,span.highlight {
        background: $duisburgDE-primary;
        border-color: $duisburgDE-primary;
      }
    }
    .tip{
      background-color: $duisburgDE-primary;
    }


    // Location
    .event-list-panel .body address {
      .fa {
        color: $duisburgDE-primary;
      }

      a {
        &:active,
        &:focus,
        &:hover {
          color: $duisburgDE-primary;
        }
      }
    }

    // Buttons
    .btn-primary:not(.btn-ghost) {
      background-color: $duisburgDE-primary;
      border-color: $duisburgDE-primary;

      &:hover,
      &:focus,
      &:active {
        background-color: darken($duisburgDE-primary, 10%);
        border-color: darken($duisburgDE-primary, 10%);
      }
    }

    .btn-ghost.btn-primary {
      border-color: $duisburgDE-primary;
      color: $duisburgDE-primary;

      &:hover,
      &:focus,
      &:active {
        color: #fff;
        background-color: $duisburgDE-primary;
      }
    }
    .dataTables_paginate .pagination .paginate_button.active, .dataTables_paginate .pagination .paginate_button:focus, .dataTables_paginate .pagination .paginate_button:hover{
      background-color: $duisburgDE-primary;
    }

  }
}